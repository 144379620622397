<template>
  <section id="imprint" class="bg-dark-subtle pt-5">
    <b-container>
      <h2>Impressum</h2>
      <b-row>
        <b-col md="4">
          <h5>Primus Services</h5>
          <p>
            Wittbräucker Str. 115a<br>
            44287 Dortmund<br>
            Germany
          </p>
        </b-col>
        <b-col md="4">
          <h5>Kontakt</h5>
          <p>
            Name: Jörg C. Brose<br>
            Email: info@primus-services.de<br>
            Phone:  +49 231 108 721 098
          </p>
        </b-col>
        <b-col md="4">
          <h5>Weitere Links</h5>
          <p>
            Datenschutz<br>
            Haftungsausschluss
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="text-center mt-5">&copy; 2024 Primus Services - Alle Rechte vorbehalten</p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue-next';

export default {
  name: 'AppImprint',
  components: {
    BContainer,
    BRow,
    BCol,
  },
};
</script>

<style scoped>
#imprint h2 {
  margin-bottom: 20px;
}
</style>
