<template>
  <div>
    <section id="about" class="my-5 bg-body-secondary">
      <b-container>
        <h2>About Us</h2>
        <p>
          Welcome to Primus Services. We provide consulting web applications and help non-digital companies transform their business and processes.
        </p>
      </b-container>
    </section>

    <section id="services" class="my-5 bg-body-tertiary">
      <b-container>
        <h2>Our Services</h2>
        <b-row>
          <b-col md="4">
            <b-card title="Consulting">
              <p>We offer expert consulting services to help you digitize your business.</p>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card title="Web Applications">
              <p>We develop custom web applications tailored to your business needs.</p>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card title="Digital Transformation">
              <p>Our team assists in transforming your business processes for the digital age.</p>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section id="team" class="my-5 bg-body-secondary">
      <b-container>
        <h2>Meet the Team</h2>
        <b-row>
          <b-col md="6">
            <b-card title="Joe" img-src="path/to/joe.jpg">
              <p>Joe is a co-founder and expert in digital transformation with over 10 years of experience.</p>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card title="Dan" img-src="path/to/dan.jpg">
              <p>Dan is a co-founder and specializes in web application development and consulting.</p>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section id="contact" class="my-5 bg-body-tertiary">
      <b-container>
        <h2>Contact Us</h2>
        <b-form @submit.prevent="submitForm">
          <b-form-group label="Name">
            <b-form-input v-model="form.name" required></b-form-input>
          </b-form-group>
          <b-form-group label="Email">
            <b-form-input type="email" v-model="form.email" required></b-form-input>
          </b-form-group>
          <b-form-group label="Message">
            <b-form-textarea v-model="form.message" rows="3" required></b-form-textarea>
          </b-form-group>
          <b-button type="submit" variant="primary">Send</b-button>
        </b-form>
      </b-container>
    </section>
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue-next';

export default {
  name: 'AppHome',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  methods: {
    submitForm() {
      alert(`Message sent by ${this.form.name}`);
    },
  },
};
</script>

<style scoped>
section {
  padding: 40px 0;
}

h2 {
  margin-bottom: 20px;
}
</style>
