<template>
  <div id="app">
    <NavBar />
    <div class="container mt-4">
      <router-view />
    </div>
    <AppImprint />
    <AppFooter />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import AppImprint from './components/AppImprint.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    AppImprint,
    AppFooter,
  },
};
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
}
</style>
