<template>
  <section id="footer">
  <b-container class="text-center bg-dark-subtle">
    <b-row>
      <b-col>

      </b-col>

    </b-row>
  </b-container>
  </section>
</template>

<script>
import { BContainer } from 'bootstrap-vue-next';

export default {
  name: 'AppFooter',
  components: {
    BContainer
  },
};
</script>

<style scoped>

</style>
