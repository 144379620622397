<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-navbar-brand href="#">Primus Services</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#">Home</b-nav-item>
        <b-nav-item href="#about">About Us</b-nav-item>
        <b-nav-item href="#services">Services</b-nav-item>
        <b-nav-item href="#team">Team</b-nav-item>
        <b-nav-item href="#contact">Contact</b-nav-item>
        <b-nav-item href="#imprint">Imprint</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse } from 'bootstrap-vue-next';

export default {
  name: 'NavBar',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BCollapse,
  },
};
</script>

<style scoped>
.navbar {
  margin-bottom: 20px;
}
</style>
